<template>
  <Layout>
    <div class="page_content d-flex flex-column">
      <div class="panel-head mb-5">
        {{ $t("home.title") }}
      </div>
      <div class="panel-body flex-1 d-flex">
        <template v-for="(list, index) in panelList" :key="index">
          <div
            v-if="index == 0"
            :class="['card panel-card cursor', !list.disabled && 'disabled']"
            @click="handlerJump(list.router)"
          >
            <img :src="list.icon" class="icon-company" />
          </div>
          <div
            v-else
            :class="['card panel-card', !list.disabled && 'disabled']"
          >
            <img :src="list.icon" class="card-icon" />
            <div class="card-name mt-3">{{ list.name }}</div>
          </div>
        </template>
      </div>
    </div>
  </Layout>
</template>

<script>
// 第一个Logo,国内版
let firstIcon = require('@/assets/images/home/boyanglogo.png')
if (process.env.VUE_APP_COMPANY_LOGO_CN && !JSON.parse(process.env.VUE_APP_COMPANY_LOGO_CN)) {
  // 英国版
  firstIcon = require('@/assets/images/home/dc-logo-1.png')
}
console.log('VUE_APP_COMPANY_LOGO_CN:',process.env.VUE_APP_COMPANY_LOGO_CN, typeof process.env.VUE_APP_COMPANY_LOGO_CN)

export default {
  data() {
    return {
      PageHeaderT: this.$t("menu.home"),
      PageHeaderItems: [
        {
          text: this.$t("menu.home"),
          active: true,
          href: "/homePage",
        },
      ],
      panelList: [
        {
          name: "FBD",
          icon: firstIcon,
          router: "/garment",
          disabled: true,
        },
        {
          name: this.$t("home.supplier"),
          icon: require("@/assets/images/home/home-supplier.png"),
          disabled: false,
        },
        {
          name: this.$t("home.customers"),
          icon: require("@/assets/images/home/home-customer.png"),
          disabled: false,
        },
        {
          name: this.$t("home.products"),
          icon: require("@/assets/images/home/home-product.png"),
          disabled: false,
        },
        {
          name: this.$t("home.market"),
          icon: require("@/assets/images/home/home-market.png"),
          disabled: false,
        },
        {
          name: this.$t("home.inventory"),
          icon: require("@/assets/images/home/home-stock.png"),
          disabled: false,
        },
        {
          name: this.$t("home.quality"),
          icon: require("@/assets/images/home/home-quality.png"),
          disabled: false,
        },
        {
          name: this.$t("home.comfort"),
          icon: require("@/assets/images/home/hand_feel_002.png"),
          disabled: false,
        },
        {
          name: this.$t("home.traceability"),
          icon: require("@/assets/images/home/home-traceability.png"),
          disabled: false,
        },
        {
          name: this.$t("home.circularity"),
          icon: require("@/assets/images/home/home-return.png"),
          disabled: false,
        },
        {
          name: this.$t("home.GHG"),
          icon: require("@/assets/images/home/home-co2.png"),
          disabled: false,
        },
        {
          name: this.$t("home.social"),
          icon: require("@/assets/images/home/home-env.png"),
          disabled: false,
        },
      ],
    };
  },
  computed: {},
  methods: {
    handlerJump(router) {
      if (router) {
        this.$router.push({ path: router });
      }
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
.card {
  border-radius: 1rem !important;
}
.page_content {
  padding: 30px;
  height: calc(100vh - 70px);
  background: url("../../assets/images/home/home-bg.jpg") no-repeat;
  background-size: 100% 100%;
  overflow: auto;
  .panel-head {
    color: #fff;
  }
  .panel-body {
    flex-wrap: wrap;
    justify-content: space-between;
    .panel-card {
      width: 24%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.8);
      &.disabled {
        background-color: rgba(255, 255, 255, 0.5);
        opacity: 1;
        cursor: not-allowed;
      }
      .card-name {
        text-align: center;
        color: #000;
      }
    }
  }
}
@media screen and(max-width: 1280px) {
  .panel-head {
    font-size: 1.8rem;
  }
  .card-icon {
    width: 4rem;
    height: 4rem;
  }
  .icon-company {
    width: 6rem;
    height: 6rem;
  }
}
@media screen and(min-width: 1440px) {
  .panel-head {
    font-size: 2rem;
  }
  .card-icon {
    width: 6rem;
    height: 6rem;
  }
  .icon-company {
    width: 8rem;
    height: 8rem;
  }
}
@media screen and(max-width: 1559px) {
  .card-name {
    font-size: 1.2rem;
  }
}
@media screen and(min-width: 1600px) {
  .card-name {
    font-size: 2rem;
  }
  .card-icon {
    width: 6rem;
    height: 6rem;
  }
  .icon-company {
    width: 10rem;
    height: 10rem;
  }
}
</style>
